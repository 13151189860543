<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Поиск по сайту</h1>
            </div>
            <div class="default_box_content bg_white p15">
                <div class="input_box">
                    <input class="main_input" type="text" v-model="search_input" placeholder="Введите текст" @keyup="searchTimeOut()" />
                    <select class="main_input" v-model="cat_search" @change="searchTimeOut()">
                        <option value="All">Весь сайт</option>
                        <option value="Organizations">Организации</option>
                        <option value="News">Новости</option>
                        <option value="Poster">Афиша</option>
                        <option value="Shares">Акции</option>
                    </select>
                </div>
            </div>
            <div class="default_box_content" v-if="preloader">
                <div v-if="list && list.length">
                    <div v-for="(item, index) in list.slice(0, limit)" :key="index" class="search_item">
                        <router-link :to="{name: cat[item.type].cat,params: { id:item.id }}">
                            <div v-html="cat[item.type].fullname" class="search_item_category"></div>
                            <div v-html="item.title" class="search_item_title"></div>
                            <div v-if="item.text" v-html="item.text" class="search_item_text"></div>
                        </router-link>
                    </div>
                    <button v-if="list.length > limit" class="load_next_button" @click="api_next()">Показать ещё</button>
                </div>
                <div v-if="alert" class="search_item">
                    Извините, Мы не смогли ничего найти
                </div>
            </div>
            <div v-else>
                <PreLoadBox />
            </div>
            <div v-if="cat_status">
                <Cat />
            </div>
        </div>
    </div>
</template>
<script>
import Cat from './Cat';
/* eslint-disable no-console */
export default {
    name: "Search",
    components: {
        Cat,
    },
    props: {
        search_text: String,
    },
    metaInfo() {
        return {
            title: `Поиск по сайту - `,
            meta: [
                {name: 'description', itemprop: 'description', content: `Поиск по сайту - ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Поиск по сайту - ${this.$myCity.name}`},
                {property: 'og:description', content: `Поиск по сайту - ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preloader: 'finish',
            cat_status: null,
            timer: 0,
            limit: 20,
            list: [],
            alert: false,
            search_input: null,
            cat_search: 'All',
            cat: {
                'Shares': {
                    cat: 'Shares',
                    fullname: 'Акции'
                },
                'News': {
                    cat: 'News',
                    fullname: 'Новости'
                },
                'Organizations': {
                    cat: 'OrganisationGet',
                    fullname: 'Организации'
                },
                'Poster': {
                    cat: 'Post',
                    fullname: 'Афиша'
                }
            },
        }
    },
    methods: {
        api_search: function() {
            if (this.search_input && this.search_input.toLowerCase() === 'мяу') {
                this.list = [];
                this.cat_status = 'meow';
            } else {
                this.preloader = null;
                this.cat_status = null;
                let url = this.$config.api_url + this.$route.params.city + '/Search.form';
                this.$http.post(url, {
                    query: this.search_input,
                    type: this.cat_search,
                    limit: 50
                }).then((response) => {
                    this.list = response.data.response;
                }).finally(() => {
                    if (this.list && this.list.length) {
                        this.alert = false;
                    } else {
                        this.alert = true;
                    }
                    this.preloader = 'finish';
                })
            }

        },
        api_next() {
            this.limit += 20;
        },
        searchTimeOut() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if (this.search_input && this.search_input.length >= 3) {
                    this.api_search();

                }
            }, 500);
        },

    },
    mounted() {
        if(this.search_text && this.search_text.length) {
            this.search_input = this.search_text;
            this.api_search();
        }
    }

}
</script>
<style scoped>
@media only screen and (max-width: 767.9px) {
    .search_item_category {
        position: relative;
        border-radius: 12px;
    }

    .search_item_title {
        padding: 10px 0;
    }

    .input_box input {
        margin-bottom: 10px !important;
    }

}


@media (min-width: 768px) {
    .search_item_category {
        position: absolute;
        top: 0;
        right: 0;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 12px;

    }

    .search_item_title {
        padding-right: 150px;
    }

    .search_item:hover {
        background: rgba(74, 118, 168, .05);
        transition: .3s;
    }

    .input_box {
        height: 50px;
    }

    .input_box input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-right: 175px;
    }

    .input_box select {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 170px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

}

.input_box {
    position: relative;
    width: 100%;
}


.input_box select {
    background: #fff;
    border: 2px solid #e5e5e5;
}

.main_input {
    margin-bottom: 0 !important;
}

.search_item {
    position: relative;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 20px;
    transition: .3s;
}

.search_item a {
    color: #2c3e50;

}

.search_item_category {
    padding: 5px 20px;
    background: #4a76a8;
    color: #fff;
}

.search_item_title {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.search_item_text {
    text-align: left;
    font-size: 14px;
    padding: 10px 0 0;
}
</style>